import { combineClassNames } from '@common/utils/combineClassNames';
import React from 'react';
import { Message } from '../../message';

const parsePixelsToInt = (px: any) => parseInt(px, 10);

const getStyling = (target: HTMLTextAreaElement, keys: string[]) => keys
  .map((key) => {
    return window.getComputedStyle ?
      window.getComputedStyle(target).getPropertyValue(key) :
      // @ts-expect-error
      target.currentStyle[key];
  }).map(parsePixelsToInt);

const autoresize = (target: HTMLTextAreaElement) => {
  const { style } = target;
  const [paddingTop, paddingBottom, borderTopWidth, borderBottomWidth, minHeight] = getStyling(
    target,
    ['padding-top', 'padding-bottom', 'border-top-width', 'border-bottom-width', 'min-height'],
  );

  const paddingSize = paddingTop + paddingBottom;
  const borderSize = borderTopWidth + borderBottomWidth;

  if (!minHeight) style.minHeight = `${target.offsetHeight - paddingSize - borderSize}px`;

  // Reset height to prevent a growing input when typing
  style.height = '0';
  // TODO: Magic number (2) used to prevent having a scrollbar
  // Should be properly fixed in scope of ONE-1028
  style.height = `${target.scrollHeight - paddingSize - borderSize + 2}px`;
};

const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>, onChange: (newValue: string) => void, rows?: number) => {
  onChange(e.target.value);

  if (!rows) autoresize(e.target);
};

export type TextAreaInputProps = Omit<JSX.IntrinsicElements['textarea'], 'onChange'> & {
  warning?: string | boolean;
  error?: string | boolean;
  minRows?: number;
  disableNewLine?: boolean;
  disableValidationFeedback?: boolean;
  onEnter?: () => void;
  onPaste?: () => void;
  onChange: (newValue: string) => void;
  ref?: React.RefObject<HTMLTextAreaElement>,
};

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(({
  rows,
  minRows,
  onChange,
  onEnter,
  disabled,
  disableNewLine,
  disableValidationFeedback = false,
  placeholder = '',
  className = '',
  error,
  warning,
  ...props
}, ref) => (
  <>
    <textarea
      className={combineClassNames('Form__control', 'Form__control--multiLine', className, {
        'Form__control--warning': !!warning && !disableValidationFeedback,
        'Form__control--invalid': !warning && !!error && !disableValidationFeedback,
      })}
      ref={(input) => {
        if (!input) return;

        if (ref && typeof ref === 'object') ref.current = input; // eslint-disable-line no-param-reassign
        if (!(rows || minRows)) autoresize(input);
      }}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e) => handleChange(e, onChange, rows)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && disableNewLine) e.preventDefault();
        if (e.key === 'Enter' && !e.shiftKey && onEnter) {
          onEnter();
          e.preventDefault();
        }
      }}
      rows={rows || minRows || 1}
      {...props}
    />
    <Message error={error} warning={warning} />
  </>
));

export default TextAreaInput;
