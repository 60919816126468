import * as React from 'react';

export type Props = {
  children: any,
  className?: string,
};

const CardFooter = ({ children, className }: Props) => (
  <div className={`Card__Footer ${className || ''}`}>{children}</div>
);

export default CardFooter;
