import * as React from 'react';
import * as R from 'ramda';
import Video from '../video';
import Modal from '../modal';
import Icon from '../icon';
import { FileViewerClickWrapper } from '../file-viewer';
import { EFileTypes, fileTypesStyles } from '../../definitions';
import { bytesToSize } from '../../utils/file';
import AttachmentImagePreview from './image-preview';

const previewWrapper = (Component) => function AttachmentPreview({ item, ...props }) {
  if (!item) return null;

  if (props.preview) {
    return (
      <div className="Attachment__Preview__Container">
        <Modal
          borderless
          size="fill"
          className={`modal--${item.file_type}`}
          content={(
            <Component
              item={item}
              {...R.omit(['maxHeight', 'interaction'], props)}
              interaction={false}
            />
          )}
        >
          <div className="Attachment__Preview">
            <Component
              trigger
              item={item}
              {...R.omit(['interaction'], props)}
              interaction={false}
            />
            <div className="Attachment__Preview__Overlay">
              <Icon type="zoom_in" />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return <Component item={item} {...props} />;
};

const Attachment = ({
  item,
  className,
  maxHeight,
  label,
  title,
  onOpen,
  trigger = false,
  includeLoader = false
}) => {
  const [hasFailed, setFailed] = React.useState(false);
  const meta = item.meta_data || {};

  const classNames = ['Attachment'];
  if (className) classNames.push(className);
  if (hasFailed) classNames.push('Attachment--failed');

  switch (item.file_type) {
    case EFileTypes.IMAGE:
    case EFileTypes.GIF:
      if (hasFailed) {
        return (
          <div className={classNames.join(' ')} key={item.preview || item.path}>
            <img src="/static/images/placeholder.png" alt="Broken!" width="750px" height="588px" style={{ maxHeight }} />
          </div>
        );
      }
      return (
        <AttachmentImagePreview
          classNames={classNames}
          item={item}
          title={title}
          setFailed={setFailed}
          meta={meta}
          maxHeight={maxHeight}
          label={label}
          includeLoader={includeLoader}
        />
      );
    case EFileTypes.EXTERNAL_VIDEO:
    case EFileTypes.VIDEO:
      if (!item.path && !item.video_id) return null;

      if (trigger) {
        return (
          <div className={classNames.join(' ')}>
            <img alt="VideoThumbnail" src={item.thumb_path} style={{ maxHeight }} />
          </div>
        );
      }

      return (
        <Video
          className={className}
          id={item.video_id || item.id}
          path={item.video_id ? `https://www.youtube.com/watch?v=${item.video_id}` : item.path}
          thumb={item.thumb_path}
          onStart={onOpen}
        />
      );
    default: {
      let icon = fileTypesStyles[item.file_type];

      if (!icon) icon = fileTypesStyles.UNKNOWN;

      classNames.push('Attachment--document');

      return (
        <FileViewerClickWrapper content={{ attachment: item }}>
          <div className={`Document__Container${item.file_size ? ' Document__Container--has-file-size' : ''}`}>
            <div className={classNames.join(' ')}>
              <img src={icon} alt={item.file_type} />
              <div className="Attachment__Name">
                {item.file_name}
                <small className="Document__FileSize">{bytesToSize(item.file_size)}</small>
              </div>
              <Icon type="launch" />
            </div>
          </div>
        </FileViewerClickWrapper>
      );
    }
  }
};

export default previewWrapper(Attachment);
