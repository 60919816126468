import * as React from 'react';

import { combineClassNames } from '../../utils/combineClassNames';

type Props = {
  children: React.ReactNode;
  className?: string;
  type?: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const CardContent = ({ children, className, type, onClick }: Props) => {
  const fullClassName = combineClassNames('Card__Content', className, {
    [`Card__Content--type-${type}`]: !!type,
  });

  return <div className={fullClassName} onClick={onClick}>{children}</div>;
};
