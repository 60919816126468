import { useMemo, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  target?: Element;
  children: ReactNode;
  portalKey?: string;
};

export const Portal = ({ target, children, portalKey }: PortalProps) => {
  const targetElement = useMemo<Element | void | null>(() => {
    if (target) return target;

    return document.body;
  }, [target]);

  if (!targetElement) return null;

  return createPortal(
    children,
    targetElement,
    portalKey,
  );
};
