import { ImageViewer } from './image-viewer';
import { IframeViewer } from './iframe-viewer';
import { UnsupportedViewer } from './unsupported-viewer';
import { EFileTypes } from '../../../definitions';

export { UnsupportedViewer } from './unsupported-viewer';

export const FILE_TYPE_MAPPING = {
  [EFileTypes.IMAGE]: ImageViewer,
  [EFileTypes.GIF]: ImageViewer,
  [EFileTypes.PDF]: IframeViewer,
  [EFileTypes.AUDIO]: null,
  [EFileTypes.DOCUMENT]: null,
  [EFileTypes.VIDEO]: IframeViewer,
  [EFileTypes.EXTERNAL_VIDEO]: IframeViewer,
  [EFileTypes.SPREADSHEET]: null,
  [EFileTypes.PRESENTATION]: null,
  [EFileTypes.UNKNOWN]: UnsupportedViewer,
};
