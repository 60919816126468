import React from 'react';

import type { FileViewerComponent } from '@common/components/file-viewer/file-viewer-types';

export const IframeViewer: FileViewerComponent = ({ file, handleClose }) => {
  const size = {
    width: file.meta_data?.width || '100%',
    height: file.meta_data?.height || '100%',
  };

  return (
    <div className="PdfViewer" onClick={handleClose}>
      <iframe
        {...size}
        title="pdf_viewer"
        role="presentation"
        frameBorder="0"
        src={file.path!}
      />
    </div>
  );
};
