import React from 'react';

import { combineClassNames } from '../../../../utils/combineClassNames';

import type { FileViewerComponent } from '../../file-viewer-types';

export const ImageViewer: FileViewerComponent = ({ file, className }) => (
  <div className={combineClassNames('ImageViewer', className)}>
    <img src={file.path!} alt="ImagePreview" />
  </div>
);
