import React, { ReactNode } from 'react';

import { combineClassNames } from '../../utils/combineClassNames';

type Props = {
  children: ReactNode;
  className?: string;
};

export const CardHeader = ({ className, children }: Props) => (
  <div className={combineClassNames('Card__Header', className)}>
    {children}
  </div>
);
