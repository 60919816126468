import React, { useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Portal } from '../portal';
import { Button } from '../button';
import Icon from '../icon';
import { combineClassNames } from '@utils/combineClassNames';
import { openInNewTab } from '@utils/open-in-new-tab';
import { FILE_TYPE_MAPPING, UnsupportedViewer } from './file-type-modules';
import { useOnKeyDown, useCarouselIndex, useOnUpdate } from '@common/hooks';
import type { Attachment } from '@common/types/objects';
import { EFileTypes } from '@common/definitions';

export type FileViewerContent = {
  attachment: Attachment;
  [key: string]: unknown;
};

export type FileViewerProps = {
  className?: string;
  isVisible?: boolean;
  setIsVisible: (isVisible: boolean) => void;
  content: FileViewerContent | FileViewerContent[];
  initialPage?: number;
  moreActionButtons?: React.ReactNode | ((item: FileViewerContent) => JSX.Element);
  onPageChange?: (item: FileViewerContent, page: number) => void;
  onDownloadFile?: (item: FileViewerContent) => void;
  onShow?: (item: FileViewerContent, page: number) => void;
};

export const FileViewer = ({
  className,
  isVisible,
  setIsVisible,
  content,
  initialPage,
  moreActionButtons,
  onDownloadFile,
  onPageChange,
  onShow,
}: FileViewerProps) => {
  const { t } = useTranslation();
  const { page, previousPage, nextPage } = useCarouselIndex(
    Array.isArray(content) ? content.length - 1 : 0,
    0,
    initialPage,
  );

  const isContentArray = Array.isArray(content);
  const currentItem = isContentArray ? content[page] : content;

  useOnUpdate(() => onPageChange?.(currentItem, page), [page]);
  useEffect(() => {
    if (isVisible) {
      onShow?.(currentItem, page);
    }
  }, [isVisible]);

  useOnKeyDown(({ key }) => {
    switch (key) {
      case 'Escape':
        return setIsVisible(false);
      case 'ArrowRight':
        return nextPage();
      case 'ArrowLeft':
        return previousPage();
      default: break;
    }
  }, [nextPage, previousPage]);

  const ViewerComponent = FILE_TYPE_MAPPING[currentItem.attachment.file_type || EFileTypes.UNKNOWN] || UnsupportedViewer;

  const handleClickContainer = (e: MouseEvent): void => {
    if (e.target === e.currentTarget) {
      setIsVisible(false);
    }
  };

  const handleDownloadFile = () => {
    if (!currentItem.attachment.download_path) return;

    onDownloadFile?.(currentItem);
    openInNewTab(currentItem.attachment.download_path, true);
  };

  if (!isVisible) return null;

  return (
    <Portal>
      <div className={combineClassNames('FileViewer', className)} onClick={handleClickContainer}>
        <div className="FileViewer__Header">
          <div className="FileViewer__Close" role="button" onClick={() => setIsVisible(false)}>
            <Icon type="close" />
          </div>

          <span className="FileViewer__FileName">{currentItem.attachment.file_name}</span>

          <div className="FileViewer__ActionButtons">
            {typeof moreActionButtons === 'function' ? moreActionButtons(currentItem) : moreActionButtons}
            {
              currentItem.attachment.download_path && (
                <Button
                  type="primary"
                  className="FileViewer__DownloadButton"
                  onClick={handleDownloadFile}
                >
                  {t('common:download')}
                </Button>
              )
            }
          </div>
        </div>

        {isContentArray && (
          <div role="navigation" className="FileViewer__Navigation" onClick={previousPage}>
            <Icon type="chevron_left" />
          </div>
        )}

        <ViewerComponent
          className="FileViewer__ViewerModule"
          file={currentItem.attachment}
          handleClose={() => setIsVisible(false)}
        />

        {isContentArray && (
          <div role="navigation" className="FileViewer__Navigation" onClick={nextPage}>
            <Icon type="chevron_right" />
          </div>
        )}
      </div>
    </Portal>
  );
};
