import * as React from 'react';
import { CardHeader } from './card-header';
import { CardContent } from './card-content';
import Footer from './footer';

export type Props = {
  children: any,
  containerClassName?: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
};

const Card = ({ children, containerClassName, onClick }: Props): JSX.Element => {
  const className = containerClassName ? `Card ${containerClassName}` : 'Card';

  return <div className={className} onClick={onClick}>{children}</div>;
};

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = Footer;

export default Card;
