import React, { useState, useMemo, MouseEvent } from 'react';

import { FileViewer } from './file-viewer';

import type { FileViewerProps, FileViewerContent } from './file-viewer';

type FileViewerClickWrapperProps = Omit<FileViewerProps, 'isVisible' | 'setIsVisible' | 'content'> & {
  children: JSX.Element;
  content: FileViewerContent;
};

export const FileViewerClickWrapper = ({ children, content, ...props }: FileViewerClickWrapperProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const cursor = useMemo(() => {
    switch (content.attachment.file_type) {
      case 'image':
      case 'gif':
        return 'zoom-in';
      default:
        return 'pointer';
    }
  }, [content.attachment.file_type]);

  const handleSetVisible = (e: MouseEvent) => {
    setIsVisible(true);
    children?.props?.onClick?.(e);
  };

  if (!children) return null;

  const child = React.Children.only(children);
  const newElement = React.cloneElement(child, {
    style: {
      ...child.props.style,
      cursor,
    },
    onClick: handleSetVisible,
  });

  return (
    <>
      <FileViewer {...props} content={content} isVisible={isVisible} setIsVisible={setIsVisible} />
      {newElement}
    </>
  );
};
