import React, { memo, useMemo, useState } from 'react';
import { FileViewerClickWrapper } from '../file-viewer';
import { EFileTypes } from '../../definitions';
import Spinner from '../spinner';

type ImagePreviewProps = {
  classNames: string[];
  item: any;
  title?: string;
  setFailed?: (failed: boolean) => void;
  meta: any;
  maxHeight: number;
  label?: string;
  includeLoader?: boolean;
};

const AttachmentImagePreview = memo(({
  classNames,
  item,
  title,
  setFailed,
  meta,
  maxHeight,
  label,
  includeLoader = false
}: ImagePreviewProps) => {
  const alt = item.file_type === EFileTypes.GIF ? 'GifPreview' : 'InteractiveImagePreview';

  const [loading, setLoading] = useState<boolean>(true);

  const allClassNames = [...classNames];

  if (loading && includeLoader) {
    allClassNames.push('imageLoading');
  }

  const style = useMemo(() => {
    if (includeLoader) {
      const base: Record<string, any> = {
        height: meta.height,
        maxHeight,
      };
      if (loading) {
        base.width = meta.width;
      }
      return base;
    }
    return {};
  }, [loading, meta, maxHeight, includeLoader]);

  return (
    <FileViewerClickWrapper content={{ attachment: item }}>
      <div
        className={allClassNames.join(' ')}
        key={item.preview || item.path}
        style={style}
      >
        { title && <b className="Attachment__Title">{title}</b> }
        { loading && includeLoader && <Spinner size="large" centered /> }
        <img
          alt={alt}
          src={item.path}
          width={meta.width}
          height={meta.height}
          style={{ maxHeight }}
          onError={() => setFailed && setFailed(true)}
          onLoad={() => setLoading(false)}
        />
        {
          item.file_type === EFileTypes.GIF && (
            <small className="Attachment__Giphy">
              <img src="/static/images/giphy-white.png" alt="Giphy" />
            </small>
          )
        }
        { label && <small className="Attachment__Label">{label}</small> }
      </div>
    </FileViewerClickWrapper>
  );
});

export default AttachmentImagePreview;
