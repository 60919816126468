import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../card';
import Icon from '../../../icon';
import { combineClassNames } from '../../../../utils/combineClassNames';
import { openInNewTab } from '@utils/open-in-new-tab';
import { fileTypesStyles } from '../../../../definitions';

import type { FileViewerComponent } from '../../file-viewer-types';

export const UnsupportedViewer: FileViewerComponent = ({
  className,
  file,
}) => {
  const { file_type, file_name, download_path, processing } = file;

  const { t } = useTranslation();

  return (
    <Card containerClassName={combineClassNames('UnsupportedViewer', className)}>
      {processing && (
        <Card.Content>{t('common:file_being_processed')}</Card.Content>
      )}
      {/* @ts-expect-error */}
      <Card.Content className="UnsupportedViewer__Content" onClick={() => openInNewTab(download_path, true)}>
        <div className="UnsupportedViewer__FileIcon">
          {/* @ts-expect-error */}
          <img alt={`${file_type}Icon`} src={fileTypesStyles[file_type]} />
          <div>
            <Icon type="download" />
          </div>
        </div>

        <div className="UnsupportedViewer__Text">
          <span className="UnsupportedViewer__FileName">{file_name}</span>
          <span className="UnsupportedViewer__CTA">{t('common:click_to_download')}</span>
        </div>
      </Card.Content>
    </Card>
  );
};
